<template>
  <v-container>
    <v-row class="mb-10">
      <v-col class="text-center">
        <h1 class="header__title">{{ $t('signUpPage.wereSoExcited') }}</h1>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <v-col cols="12" lg="6" v-intersect.once="onIntersect" class="mb-10">
        <h3 class="text-center header-secondary header-span">{{$t('signUpPage.fewDetails')}}</h3>
      </v-col>
      <v-col cols="12" lg="6" class="d-flex justify-center">
        <v-img contain :src="bookCover" alt="Product sample" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col>
        <v-form
            @submit.prevent="submit"
            lazy-validation
            v-model="valid"
            ref="form">
          <AlertError :error="error" />
          <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('signUpPage.firstName')"
            v-model="firstName"
            :rules="rules.firstName"
            required
          />
          <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('signUpPage.lastName')"
            v-model="lastName"
            :rules="rules.lastName"
            required
          />
          <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('signUpPage.emailAddress')"
            v-model="email"
            :rules="rules.email"
            type="email"
            required
          />
          <TextField
            class="mb-lg-10 mb-5"
            :placeholder="$t('signUpPage.createPassword')"
            v-model="pass"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="rules.pass"
          />
          <AlertError :error="error" />
          <v-btn block depressed class="btn btn-img btn--pattern mb-10 mb-md-15" @click="submit">
            <span class="btn__text btn__text--white">{{ $t('signUpPage.signUp') }}</span>
          </v-btn>
        </v-form>
        <div>By clicking Sign Up, I'm giving my consent for Jambooks to send me texts to build my Jambook, and to send future promotional and informational texts. I can opt-out anytime by replying STOP to any message I receive. Reply HELP for help. Reply STOP to end. Message and data rates may apply. Messages sent by Jambooks, LLC.</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextField from '@/components/TextField';
import { validatePhone } from '@/lib/'
import AlertError from '@/components/AlertError';

  export default {
    name: 'SignUp',
    components: {
      TextField,
      AlertError
    },
    props: {
      error: {
        type: String
      }
    },
    data() {
      return {
        errorMsg: null,
        dialog: false,
        valid: true,
        sendValid: true,
        sendPhone: null,
        firstName: null,
        lastName: null,
        email: null,
        pass: null,
        showPassword: false,
        showLogo: false,
        sendLabel: 'Send',
        sendSent: false,
        rules: {
          firstName: [
            v => !!v || 'First Name is required',
          ],
          lastName: [
            v => !!v || 'Last Name is required',
          ],
          email: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],
          phone: [
            value => validatePhone(value) || this.$t('login.badPhone')
          ],
          pass: [
            v => !!v || 'Password is required',
            v => /^.{6,64}$/.test(v) || 'Password must be at least six characters long and no longer than 64.'
          ]
        },
      }
    },
    computed: {
      jambook() {
        return require('@/assets/Jambook_Large.svg')
      },
      bookCover() {
        return require('@/assets/margobook.png');
      },
      form () {
        return {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          pass: this.pass
        }
      }
    },
    methods: {
      submit: function() {
        if (this.$refs.form.validate()) {
          this.$emit('register', this.form);
        } else {
          this.error = 'Please correct all missing or invalid data.';
        }
      },
      submitPhone: function() {
        if (this.$refs.phoneForm.validate()) {
          this.$emit('resend', this.sendPhone);
          this.sendSent = true;
          this.sendLabel = 'Text Sent';
        }
      },
      onIntersect(entries) {
        this.showLogo = entries[0].isIntersecting;
      },
    },
    watch: {
      dialog: function(newVal) {
        if (!newVal) {
          this.sendLabel = 'Send';
          this.sendSent = false;
          this.sendPhone = null;
        }
      }
    }
  }
</script>

<style scoped>
  .header-secondary {
    font-size: 5rem;
    text-transform: uppercase;
    line-height: 100%
  }
  .btn {
    position: relative;
  }
  .btn-img {
    background-image: url(../assets/Sign_Up_Button.svg);
    background-size: cover;
    background-position: center;
  }
  @media only screen and (max-width: 600px) {
    .header__title {
      font-size: 2rem;
    }
    .header-secondary {
      font-size: 2rem;
    }
    .header__img {
      height: auto;
      width: 13rem;
    }
  }
</style>
