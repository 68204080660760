<template>
  <v-container>
    <RegisterForm
      @register="handleRegister"
      @validateCode="handleValidateCode"
      @resendCode="handleResendCode"
      :error="error"
      :resendLoading="resendLoading"
    />
  </v-container>
</template>a

<script>
import RegisterForm from '../components/RegisterForm';
import Http from '@/api/Http';
import { getterNames } from '@/store/global';

export default {
  name: 'Register',
  components: {
    RegisterForm
  },
  data() {
    return {
      error: null,
      resendLoading: false,
      api: Http
        .use('data')
    }
  },
  computed: {
    regPhone: function () {
      return this.$store.getters[getterNames.regPhone];
    }
  },
  methods: {
    handleRegister(user) {
      this.error = null;
      user.phone = this.regPhone.replace(/\D/g, '');
      this.api.post('/auth/register', user)
        .then(() => {
          this.$router.push('/login');
        })
        .catch(err => {
          this.error = err.response.data.error;
        });
    },
    handleValidateCode(code) {
      if (code) {
        this.api.post('/auth/verifyCode', {code})
            .then(() => {
              this.error = null;
            })
            .catch((err) => {
              this.error = err.response.data.error;
            });
      }
    },
    handleResendCode() {
      this.resendLoading = true;
      this.api.post('/auth/join', { phone: this.regPhone })
        .then(() => {
          this.resendLoading = false;
        })
        .catch((err) => {
          this.resendLoading = false;
          this.error = err.response.data.error;
        });
    }
  },
  created() {
    if (this.regPhone === '') {
      this.$router.push('/signup');
    }
  }
}
</script>
